// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/BlackredBackground.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.viewButtonUsers, .deleteButton {
  
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    margin-right: 10px;
  }
  
  .deleteButton {
    background-color: #dc3545;
  }
  
  .userDetails {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    border:2px solid #ccc;
    color:#fff;
    text-align: center;
  }
  .userDetails p{
    margin-bottom: 5px;
  }
  .userDetails img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .closeButton {
    margin-top: 20px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #6c757d;
    color: white;
  }
  
  
  
@media (max-width: 767px){
.mobileItemOne{
  margin-top:-100px;
}
}`, "",{"version":3,"sources":["webpack://./src/Components/Admin/RegisteredUsers.css"],"names":[],"mappings":";AACA;;IAEI,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,kBAAkB;IAClB,kBAAkB;IAClB,uCAAuC;IACvC,aAAa;IACb,yDAA0D;IAC1D,qBAAqB;IACrB,UAAU;IACV,kBAAkB;EACpB;EACA;IACE,kBAAkB;EACpB;EACA;IACE,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,YAAY;EACd;;;;AAIF;AACA;EACE,iBAAiB;AACnB;AACA","sourcesContent":["\n.viewButtonUsers, .deleteButton {\n  \n    padding: 8px 16px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    background-color: #007bff;\n    color: white;\n    margin-right: 10px;\n  }\n  \n  .deleteButton {\n    background-color: #dc3545;\n  }\n  \n  .userDetails {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    padding: 30px 40px;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n    z-index: 1000;\n    background-image: url(../../Assets/BlackredBackground.png);\n    border:2px solid #ccc;\n    color:#fff;\n    text-align: center;\n  }\n  .userDetails p{\n    margin-bottom: 5px;\n  }\n  .userDetails img {\n    width: 100px;\n    height: 100px;\n    border-radius: 50%;\n    margin-bottom: 20px;\n  }\n  \n  .closeButton {\n    margin-top: 20px;\n    padding: 8px 16px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    background-color: #6c757d;\n    color: white;\n  }\n  \n  \n  \n@media (max-width: 767px){\n.mobileItemOne{\n  margin-top:-100px;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
