import React from 'react'
import CreateAccount from './CreateAccount'
import Membership from './Membership'
import MembershipCheckout from './MembershipCheckout'
const Registration = () => {
  return (
    <div>
    <CreateAccount />
    </div>
  )
}

export default Registration
