import React from 'react'
import AddTokensToWallet from './AddTokensToWallet'
import Profile from './Profile'

const UserProfile = () => {
  return (
    <div>
    <Profile />
    </div>
  )
}

export default UserProfile
