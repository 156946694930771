// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminLogin .login-wrapper .loginCard{
    margin-top:1px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Login/AdminLogin.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB","sourcesContent":[".adminLogin .login-wrapper .loginCard{\n    margin-top:1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
