// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popupwrapped{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    height:500px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.popupwrapped h2 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #333;
    
}

.popupwrapped p {
    margin-bottom: 20px;
    color: #666;
    font-size: 1rem;
}
.popupwrapped img{
    width:130px;
    margin:20px 0;
    border-radius: 50%;
}
.popupwrapped button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    margin:0 10px;
}

.popup button.cancel {
    background-color: #dc3545;
   
}

.popup button:hover {
    background-color: #0056b3;
}

.popup button.cancel:hover {
    background-color: #c82333;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Admin/popup.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;IACb,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,WAAW;;AAEf;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,eAAe;AACnB;AACA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,yBAAyB;;AAE7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".popupwrapped{\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 400px;\n    padding: 20px;\n    background-color: white;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    z-index: 1000;\n    height:500px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n}\n\n.popupwrapped h2 {\n    margin-top: 0;\n    font-size: 1.5rem;\n    color: #333;\n    \n}\n\n.popupwrapped p {\n    margin-bottom: 20px;\n    color: #666;\n    font-size: 1rem;\n}\n.popupwrapped img{\n    width:130px;\n    margin:20px 0;\n    border-radius: 50%;\n}\n.popupwrapped button {\n    padding: 10px 15px;\n    border: none;\n    border-radius: 5px;\n    background-color: #007bff;\n    color: white;\n    font-size: 1rem;\n    cursor: pointer;\n    margin:0 10px;\n}\n\n.popup button.cancel {\n    background-color: #dc3545;\n   \n}\n\n.popup button:hover {\n    background-color: #0056b3;\n}\n\n.popup button.cancel:hover {\n    background-color: #c82333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
