// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imgWrapLeague{
    z-index: 1 !important;
}
.fightsWrap{
    z-index: 0 !important;
}
.fightItem{
    z-index: 0 !important;
}
.admin-header{
    z-index: 99999 !important;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA;IACI,qBAAqB;AACzB;AACA;IACI,qBAAqB;AACzB;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".imgWrapLeague{\n    z-index: 1 !important;\n}\n.fightsWrap{\n    z-index: 0 !important;\n}\n.fightItem{\n    z-index: 0 !important;\n}\n.admin-header{\n    z-index: 99999 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
