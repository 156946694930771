// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publicProfileWrapper .member-header-image{
   
    position: absolute;
    top: -15px;
    left: 80px;
    width: 70px;
    height: 70px;
    border: 3px solid crimson;
    border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/Components/UserProfile/publicProfile.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;AACtB","sourcesContent":[".publicProfileWrapper .member-header-image{\n   \n    position: absolute;\n    top: -15px;\n    left: 80px;\n    width: 70px;\n    height: 70px;\n    border: 3px solid crimson;\n    border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
