// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/banner.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upcomingFightsUser{
    min-height: 100vh;
    background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    padding-top: 120px;
    padding-bottom: 50px;
}`, "",{"version":3,"sources":["webpack://./src/Components/UpcomingFights/UpcomingFightsUser.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,0GAA+F;IAC/F,kBAAkB;IAClB,oBAAoB;AACxB","sourcesContent":[".upcomingFightsUser{\n    min-height: 100vh;\n    background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url(../../Assets/banner.png);\n    padding-top: 120px;\n    padding-bottom: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
