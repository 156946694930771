// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.global-leaderboard .homeThird{
    background-color: transparent;
    margin-top:-90px;
}

@media (max-width: 767px){
    .global-leaderboard .homeThird{
        margin-top:-30px;
    }
      
}`, "",{"version":3,"sources":["webpack://./src/Components/GlobalLeaderboard/GlobalLeaderboard.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;IACI;QACI,gBAAgB;IACpB;;AAEJ","sourcesContent":[".global-leaderboard .homeThird{\n    background-color: transparent;\n    margin-top:-90px;\n}\n\n@media (max-width: 767px){\n    .global-leaderboard .homeThird{\n        margin-top:-30px;\n    }\n      \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
